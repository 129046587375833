import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import { Collapse } from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import {
  Grid,
  ChevronRight,
  // Settings,
  // Activity,
  ExternalLink
} from 'react-feather';



import { useSelector } from 'react-redux';



import axios from "axios";
import { baseUrl } from "../../config/config"
import { refreshToken, toastNoti } from '../../utils/ConstUtils'



const SidebarMenu = (props) => {
  const loginDataStore = useSelector(state => state.loginReducer);
  const userLoginRole = loginDataStore.data?.userLoginInfo?.userRole || [];

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [UsersOpen, setUsersOpen] = useState(false);
  const toggleUsersOpen = (event) => {
    setUsersOpen(!UsersOpen);
    event.preventDefault();
  };  

  const [schoolsOpen, setschoolsOpen] = useState(false);
  const toggleschools = (event) => {
    setschoolsOpen(!schoolsOpen);
    event.preventDefault();
  };

  const [elementsOpen, setElementsOpen] = useState(false);
  const toggleElements = (event) => {
    setElementsOpen(!elementsOpen);
    event.preventDefault();
  };

  const [designSystemOpen, setDesignSystemOpen] = useState(false);
  const toggleDesignSystem = (event) => {
    setDesignSystemOpen(!designSystemOpen);
    event.preventDefault();
  };

  const [blocksOpen, setBlocksOpen] = useState(false);
  const toggleBlocks = (event) => {
    setBlocksOpen(!blocksOpen);
    event.preventDefault();
  };

  const [uiKitComponentsOpen, setUiKitComponents] = useState(false);
  const toggleUiKitComponents = (event) => {
    setUiKitComponents(!uiKitComponentsOpen);
    event.preventDefault();
  };

  const [CourseApproveOpen, setCourseApproveOpen] = useState(false);
  const toggleCourseApprove = (event) => {
    setCourseApproveOpen(!CourseApproveOpen);
    event.preventDefault();
  };

  const [SysOperationOpen, setSysOperationOpen] = useState(false);
  const toggleSysOperation = (event) => {
    setSysOperationOpen(!SysOperationOpen);
    event.preventDefault();
  };

  const [SysTermsOpen, setSysTermsOpen] = useState(false);
  const toggleSysTerms = (event) => {
    setSysTermsOpen(!SysTermsOpen);
    event.preventDefault();
  };

  const [GoodsOpen, setGoodsOpen] = useState(false);
  const toggleGoodsOpen = (event) => {
    setGoodsOpen(!GoodsOpen);
    event.preventDefault();
  };

  const [ChargeOpen, setChargeOpen] = useState(false);
  const toggleChargeOpen = (event) => {
    setChargeOpen(!ChargeOpen);
    event.preventDefault();
  };
/*
  const [oldSystemLinkOpen, setOldSystemLink] = useState(false);
  const toggleOldSystemLink = (event) => {
    setOldSystemLink(!oldSystemLinkOpen);
    event.preventDefault();
  };
*/

const [ReserveOpen, setReserveOpen] = useState(false);
const toggleReserveOpen = (event) => {
  setReserveOpen(!ReserveOpen);
  event.preventDefault();
};

const [SurveyOpen, setSurveyOpen] = useState(false);
const toggleSurveyOpen = (event) => {
  setSurveyOpen(!SurveyOpen);
  event.preventDefault();
};



const [posAppDownloadLink, SetPosAppDownloadLink] = useState("");
const [kioskAppDownloadLink, SetKioskAppDownloadLink] = useState("");
const [manualDownloadLink, SetManualDownloadLink] = useState("");
const [dotNetDownloadLink, SetDotNetDownloadLink] = useState("");

const GetPosAppDownloadLink = () => {
  let httpHeaderInfo = {
    headers: {
      'Content-Type': 'application/json',
      'access_token': localStorage.getItem("accessToken")
    }
  };

  axios
    .get(baseUrl + "/app/name/pos/filename/1", httpHeaderInfo)
    .then((res) => {
      if(res.data.status==="200") {
        SetPosAppDownloadLink(baseUrl + res.data.appNameView.fileName);
      } else if(res.data.status==="411") {
        refreshToken();
      } else {
        toastNoti(res.data.error);
      }
    })
    .catch(function (error) {
      toastNoti("서버와의 통신이 원활치 않습니다. 잠시 후 다시 시도해주세요.");
      if(error?.reponse?.error) toastNoti("오류 : " + error.reponse.error);
  });
};

const GetManualDownloadLink = () => {
  let httpHeaderInfo = {
    headers: {
      'Content-Type': 'application/json',
      'access_token': localStorage.getItem("accessToken")
    }
  };

  axios
    .get(baseUrl + "/app/name/pos/filename/2", httpHeaderInfo)
    .then((res) => {
      if(res.data.status==="200") {
        SetManualDownloadLink(baseUrl + res.data.appNameView.fileName);
      } else if(res.data.status==="411") {
        refreshToken();
      } else {
        toastNoti(res.data.error);
      }
    })
    .catch(function (error) {
      toastNoti("서버와의 통신이 원활치 않습니다. 잠시 후 다시 시도해주세요.");
      if(error?.reponse?.error) toastNoti("오류 : " + error.reponse.error);
  });
};

const GetDotNetDownloadLink = () => {
  let httpHeaderInfo = {
    headers: {
      'Content-Type': 'application/json',
      'access_token': localStorage.getItem("accessToken")
    }
  };

  axios
    .get(baseUrl + "/app/name/pos/filename/3", httpHeaderInfo)
    .then((res) => {
      if(res.data.status==="200") {
        SetDotNetDownloadLink(baseUrl + res.data.appNameView.fileName);
      } else if(res.data.status==="411") {
        refreshToken();
      } else {
        toastNoti(res.data.error);
      }
    })
    .catch(function (error) {
      toastNoti("서버와의 통신이 원활치 않습니다. 잠시 후 다시 시도해주세요.");
      if(error?.reponse?.error) toastNoti("오류 : " + error.reponse.error);
  });
};

const GetKioskAppDownloadLink = () => {
  let httpHeaderInfo = {
    headers: {
      'Content-Type': 'application/json',
      'access_token': localStorage.getItem("accessToken")
    }
  };

  axios
    .get(baseUrl + "/app/name/pos/filename/4", httpHeaderInfo)
    .then((res) => {
      if(res.data.status==="200") {
        SetKioskAppDownloadLink(baseUrl + res.data.appNameView.fileName);
      } else if(res.data.status==="411") {
        refreshToken();
      } else {
        toastNoti(res.data.error);
      }
    })
    .catch(function (error) {
      toastNoti("서버와의 통신이 원활치 않습니다. 잠시 후 다시 시도해주세요.");
      if(error?.reponse?.error) toastNoti("오류 : " + error.reponse.error);
  });
};



useEffect(() => {
  GetPosAppDownloadLink();
  GetManualDownloadLink();
  GetDotNetDownloadLink();
  GetKioskAppDownloadLink();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


  
return (
    <>

{ !userLoginRole.length ? '로딩중...' :

<>
{ userLoginRole[0].authority === "ROLE_ADMIN" ?

      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>기초정보 관리</span>
          </div>
          <ul>
            <li>
              <a
                href="#/"
                onClick={toggleUsersOpen}
                className={clsx({ active: UsersOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">회원관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={UsersOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Users">
                      회원관리
                    </NavLink>
                  </li>
                </ul>
              </Collapse>              
            </li>
            <li>
              <a
                href="#/"
                onClick={toggleschools}
                className={clsx({ active: schoolsOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">학교관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={schoolsOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Schools/schools">
                      학교관리
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Schools/menus">
                      메뉴관리
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Schools/account">
                      학교/식당 계정관리
                    </NavLink>
                  </li>                  
                </ul>
              </Collapse>
            </li>
          </ul>
          <div className="sidebar-header">
            <span>시스템 운영</span>
          </div>
          <ul>
          <li>
              <a
                href="#/"
                onClick={toggleCourseApprove}
                className={clsx({ active: CourseApproveOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">과정승인</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={CourseApproveOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Approve/course">
                      과정 승인
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>            
            <li>
              <a
                href="#/"
                onClick={toggleDesignSystem}
                className={clsx({ active: designSystemOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">식권관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={designSystemOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Tickets/prepaid">
                      선불식권 관리
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Tickets/prepaidrefund">
                      만료 선불식권 환불
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Tickets/paylater">
                      후불권한 승인
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Tickets/paylaterrefund">
                      만료 후불식권 환불
                    </NavLink>
                  </li>                  
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Tickets/service">
                      서비스식권 발급
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Tickets/servicerefund">
                      만료 서비스식권 환불
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Tickets/usedlist">
                      사용내역 조회
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Tickets/unusedlist">
                      미사용내역 조회
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Tickets/paidrefund">
                      만료 결제식권 환불
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Tickets/ticketstop">
                      일시 사용중지 / 복구
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <a
                href="#/"
                onClick={toggleChargeOpen}
                className={clsx({ active: ChargeOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">충전금 관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={ChargeOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Charge/approve">
                      충전금 승인
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Charge/charge">
                      충전금 충전
                    </NavLink>
                  </li>                  
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Charge/chargeList">
                      충전금 내역
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Charge/calc">
                      충전금 정산
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Charge/calcListDetail">
                      충전금 정산내역
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Charge/calcList">
                      충전금 정산현황
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <a
                href="#/"
                onClick={toggleBlocks}
                className={clsx({ active: blocksOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">정산관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={blocksOpen}>
                <ul>
                <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Calcs/paylater">
                      후불정산
                    </NavLink>
                  </li>                  
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Calcs/calc">
                      정산
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Calcs/pay">
                      결제 조회
                    </NavLink>
                  </li>                  
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Calcs/refund">
                      환불 / 취소 조회
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <a
                href="#/"
                onClick={toggleUiKitComponents}
                className={clsx({ active: uiKitComponentsOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">현황관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={uiKitComponentsOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Calcs/sales">
                      매출 현황
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Calcs/kiosk">
                      키오스크별 매출 현황
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Views/ticket">
                      식권 사용현황
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/views/shop">
                      식당 사용현황
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Views/course">
                      과정 사용현황
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Views/mealtype">
                      시간별 사용현황
                    </NavLink>
                  </li>                  
                </ul>
              </Collapse>
            </li>
{/*
            <li>
              <a
                href="#/"
                onClick={toggleReserveOpen}
                className={clsx({ active: ReserveOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">예약관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={ReserveOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Reserve/pay">
                      예약 목록
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Reserve/refund">
                      예약환불 목록
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Reserve/expired">
                      예약만료 목록
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Reserve/views">
                      예약 현황
                    </NavLink>
                  </li>                
                </ul>
              </Collapse>
            </li>
            <li>
              <a
                href="#/"
                onClick={toggleSurveyOpen}
                className={clsx({ active: SurveyOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">설문관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={SurveyOpen}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Survey/reg">
                      설문 목록
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Survey/views">
                      설문 현황
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Survey/calc">
                      설문 통계
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Survey/menu">
                      일별 메뉴 현황
                    </NavLink>
                  </li>                     
                </ul>
              </Collapse>
            </li>
*/}
            <li>
              <a
                href="#/"
                onClick={toggleElements}
                className={clsx({ active: elementsOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">포인트관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={elementsOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Points/point">
                      포인트 현황 및 지급
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Points/issuelist">
                      포인트 획득 내역
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Points/usedlist">
                      포인트 사용 내역
                    </NavLink>
                  </li>                  
                </ul>
              </Collapse>
            </li>
            <li>
              <a
                href="#/"
                onClick={toggleGoodsOpen}
                className={clsx({ active: GoodsOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">상품판매관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={GoodsOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Goods/products">
                      상품관리
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Goods/sales">
                      상품주문 내역
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Goods/calcs">
                      상품주문 정산
                    </NavLink>
                  </li>                  
                </ul>
              </Collapse>
            </li>            
          </ul>
          <div className="sidebar-header">
            <span>시스템 관리</span>
          </div>
          <ul>
            <li>
              <a
                href="#/"
                onClick={toggleSysOperation}
                className={clsx({ active: SysOperationOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">운영관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={SysOperationOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Ops/one2one">
                      1:1 문의
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} 
                      to="/Ops/notice">
                      공지사항
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Ops/faq">
                      FAQ
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Ops/alarm">
                      알림메시지
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <a
                href="#/"
                onClick={toggleSysTerms}
                className={clsx({ active: SysTermsOpen })}>
                <span className="sidebar-icon">
                  <Grid />
                </span>
                <span className="sidebar-item-label">약관관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={SysTermsOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Ops/aboutus">
                      사업자 정보
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Ops/terms">
                      서비스 이용약관
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Ops/privacy">
                      개인정보 처리 방침
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Ops/online">
                      전자금융거래 이용약관
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Ops/autorenewal">
                      자동승인 이용약관
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Ops/deleteAccountInfo">
                      회원탈퇴 안내
                    </NavLink>
                  </li>                  
                </ul>
              </Collapse>
            </li>            
          </ul>
{/*
          <div className="sidebar-header">
            <span>시스템 설정</span>
          </div>
          <ul>
            <li>
              <a
                href="#/"
                onClick={toggleWidgets}
                className={clsx({ active: widgetsOpen })}>
                <span className="sidebar-icon">
                  <Settings />
                </span>
                <span className="sidebar-item-label">설정관리</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={widgetsOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Systems/level">
                      운영자 관리
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Systems/manager">
                      운영자 등급 관리
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>            
          </ul>
*/}
          <div className="sidebar-menu-box">
            <div className="sidebar-header">
              <span>다운로드</span>
            </div>
            <ul>
              <li>
                <a
                  className="font-weight-normal"
                  href={manualDownloadLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="sidebar-icon">
                    <ExternalLink />
                  </span>
                  <span className="sidebar-item-label">
                    학식 운영자 메뉴얼
                  </span>
                </a>
              </li>              
              <li>
                <a
                  className="font-weight-normal"
                  href={posAppDownloadLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="sidebar-icon">
                    <ExternalLink />
                  </span>
                  <span className="sidebar-item-label">
                    학식 POS 앱
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="font-weight-normal"
                  href={kioskAppDownloadLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="sidebar-icon">
                    <ExternalLink />
                  </span>
                  <span className="sidebar-item-label">
                    학식 KIOSK 앱
                  </span>
                </a>
              </li>              
              <li>
                <a
                  className="font-weight-normal"
                  href={dotNetDownloadLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="sidebar-icon">
                    <ExternalLink />
                  </span>
                  <span className="sidebar-item-label">
                    .NET Framework 4.7.2
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="sidebar-menu-box">
            <div className="sidebar-header">
              <span>외부링크</span>
            </div>
            <ul>
              <li>
                <a
                  className="font-weight-normal"
                  href="http://www.haksik.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="sidebar-icon">
                    <ExternalLink />
                  </span>
                  <span className="sidebar-item-label">
                    학식 홈페이지
                  </span>
                </a>
              </li>
{/*
              <li>
                <a
                  href="#/"
                  onClick={toggleOldSystemLink}
                  className={clsx('font-weight-normal', {
                    active: oldSystemLinkOpen
                  })}>
                  <span className="sidebar-icon">
                    <Activity />
                  </span>
                  <span className="sidebar-item-label text-capitalize">
                    이전 시스템 연결
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={oldSystemLinkOpen}>
                  <ul className="pb-0">
                    <li>
                      <a
                        href="http://admin.haksik.com"
                        target="_blank"
                        rel="noopener noreferrer">
                        <span className="sidebar-icon">
                          <ExternalLink />
                        </span>
                        <span className="sidebar-item-label">
                          학식 어드민
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://cms.haksik.com"
                        target="_blank"
                        rel="noopener noreferrer">
                        <span className="sidebar-icon">
                          <ExternalLink />
                        </span>
                        <span className="sidebar-item-label">
                          학식 CMS
                        </span>
                      </a>
                    </li>
                  </ul>
                </Collapse>
              </li>
*/}
            </ul>
          </div>
        </div>
      </PerfectScrollbar>

: 

<>
{ userLoginRole[0].authority === "ROLE_SCHOOL" ? 

<PerfectScrollbar>
<div className="sidebar-navigation">
  <div className="sidebar-header">
    <span>기초정보 관리</span>
  </div>
  <ul>
    <li>
      <a
        href="#/"
        onClick={toggleUsersOpen}
        className={clsx({ active: UsersOpen })}>
        <span className="sidebar-icon">
          <Grid />
        </span>
        <span className="sidebar-item-label">회원관리</span>
        <span className="sidebar-icon-indicator">
          <ChevronRight />
        </span>
      </a>
      <Collapse isOpen={UsersOpen}>
        <ul>
          <li>
            <NavLink onClick={toggleSidebarMobile} to="/Users">
              회원관리
            </NavLink>
          </li>
        </ul>
      </Collapse>              
    </li>
    <li>
      <a
        href="#/"
        onClick={toggleschools}
        className={clsx({ active: schoolsOpen })}>
        <span className="sidebar-icon">
          <Grid />
        </span>
        <span className="sidebar-item-label">학교관리</span>
        <span className="sidebar-icon-indicator">
          <ChevronRight />
        </span>
      </a>
      <Collapse isOpen={schoolsOpen}>
        <ul>
          <li>
            <NavLink onClick={toggleSidebarMobile} to="/Schools/schools">
              학교관리
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Schools/menus">
              메뉴관리
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Schools/account">
              학교/식당 계정관리
            </NavLink>
          </li>                  
        </ul>
      </Collapse>
    </li>
  </ul>
  <div className="sidebar-header">
    <span>시스템 운영</span>
  </div>
  <ul>
  <li>
      <a
        href="#/"
        onClick={toggleCourseApprove}
        className={clsx({ active: CourseApproveOpen })}>
        <span className="sidebar-icon">
          <Grid />
        </span>
        <span className="sidebar-item-label">과정승인</span>
        <span className="sidebar-icon-indicator">
          <ChevronRight />
        </span>
      </a>
      <Collapse isOpen={CourseApproveOpen}>
        <ul>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Approve/course">
              과정 승인
            </NavLink>
          </li>
        </ul>
      </Collapse>
    </li>            
    <li>
      <a
        href="#/"
        onClick={toggleDesignSystem}
        className={clsx({ active: designSystemOpen })}>
        <span className="sidebar-icon">
          <Grid />
        </span>
        <span className="sidebar-item-label">식권관리</span>
        <span className="sidebar-icon-indicator">
          <ChevronRight />
        </span>
      </a>
      <Collapse isOpen={designSystemOpen}>
        <ul>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Tickets/prepaid">
              선불식권 관리
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Tickets/prepaidrefund">
              만료 선불식권 환불
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Tickets/paylater">
              후불권한 승인
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Tickets/paylaterrefund">
              만료 후불식권 환불
            </NavLink>
          </li>                       
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Tickets/usedlist">
              사용내역 조회
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Tickets/unusedlist">
              미사용내역 조회
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Tickets/paidrefund">
              만료 결제식권 환불
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Tickets/ticketstop">
              일시 사용중지 / 복구
            </NavLink>
          </li>
        </ul>
      </Collapse>
    </li>
    <li>
      <a
        href="#/"
        onClick={toggleChargeOpen}
        className={clsx({ active: ChargeOpen })}>
        <span className="sidebar-icon">
          <Grid />
        </span>
        <span className="sidebar-item-label">충전금 관리</span>
        <span className="sidebar-icon-indicator">
          <ChevronRight />
        </span>
      </a>
      <Collapse isOpen={ChargeOpen}>
        <ul>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Charge/approve">
              충전금 승인
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Charge/charge">
              충전금 충전
            </NavLink>
          </li>                  
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Charge/chargeList">
              충전금 내역
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Charge/calc">
              충전금 정산
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Charge/calcListDetail">
              충전금 정산내역
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Charge/calcList">
              충전금 정산현황
            </NavLink>
          </li>          
        </ul>
      </Collapse>
    </li>    
    <li>
      <a
        href="#/"
        onClick={toggleBlocks}
        className={clsx({ active: blocksOpen })}>
        <span className="sidebar-icon">
          <Grid />
        </span>
        <span className="sidebar-item-label">정산관리</span>
        <span className="sidebar-icon-indicator">
          <ChevronRight />
        </span>
      </a>
      <Collapse isOpen={blocksOpen}>
        <ul>
        <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Calcs/paylater">
              후불정산
            </NavLink>
          </li>                  
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Calcs/calc">
              정산
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Calcs/pay">
              결제 조회
            </NavLink>
          </li>                  
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Calcs/refund">
              환불 / 취소 조회
            </NavLink>
          </li>
        </ul>
      </Collapse>
    </li>
    <li>
      <a
        href="#/"
        onClick={toggleUiKitComponents}
        className={clsx({ active: uiKitComponentsOpen })}>
        <span className="sidebar-icon">
          <Grid />
        </span>
        <span className="sidebar-item-label">현황관리</span>
        <span className="sidebar-icon-indicator">
          <ChevronRight />
        </span>
      </a>
      <Collapse isOpen={uiKitComponentsOpen}>
        <ul>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Calcs/sales">
              매출 현황
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Calcs/kiosk">
              키오스크별 매출 현황
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Views/ticket">
              식권 사용현황
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/views/shop">
              식당 사용현황
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Views/course">
              과정 사용현황
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Views/mealtype">
              시간별 사용현황
            </NavLink>
          </li>                  
        </ul>
      </Collapse>
    </li>
{/*
    <li>
      <a
        href="#/"
        onClick={toggleReserveOpen}
        className={clsx({ active: ReserveOpen })}>
        <span className="sidebar-icon">
          <Grid />
        </span>
        <span className="sidebar-item-label">예약관리</span>
        <span className="sidebar-icon-indicator">
          <ChevronRight />
        </span>
      </a>
      <Collapse isOpen={ReserveOpen}>
        <ul>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Reserve/pay">
              예약 목록
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Reserve/refund">
              예약환불 목록
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Reserve/expired">
              예약만료 목록
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={toggleSidebarMobile}
              to="/Reserve/views">
              예약 현황
            </NavLink>
          </li>                
        </ul>
      </Collapse>
    </li>
    <li>
      <a
        href="#/"
        onClick={toggleSurveyOpen}
        className={clsx({ active: SurveyOpen })}>
        <span className="sidebar-icon">
          <Grid />
        </span>
        <span className="sidebar-item-label">설문관리</span>
        <span className="sidebar-icon-indicator">
          <ChevronRight />
        </span>
      </a>
      <Collapse isOpen={SurveyOpen}>
        <ul>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Survey/reg">
              설문 목록
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Survey/views">
              설문 현황
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Survey/calc">
              설문 통계
            </NavLink>
          </li>
          <li>
            <NavLink onClick={toggleSidebarMobile} 
              to="/Survey/menu">
              일별 메뉴 현황
            </NavLink>
          </li>                     
        </ul>
      </Collapse>
    </li>
  */}
  </ul>
  <div className="sidebar-menu-box">
    <div className="sidebar-header">
      <span>다운로드</span>
    </div>
    <ul>
      <li>
        <a
          className="font-weight-normal"
          href={manualDownloadLink}
          target="_blank"
          rel="noopener noreferrer">
          <span className="sidebar-icon">
            <ExternalLink />
          </span>
          <span className="sidebar-item-label">
            학식 운영자 메뉴얼
          </span>
        </a>
      </li>      
      <li>
        <a
          className="font-weight-normal"
          href={posAppDownloadLink}
          target="_blank"
          rel="noopener noreferrer">
          <span className="sidebar-icon">
            <ExternalLink />
          </span>
          <span className="sidebar-item-label">
            학식 POS 앱
          </span>
        </a>
      </li>
      <li>
          <a
            className="font-weight-normal"
            href={kioskAppDownloadLink}
            target="_blank"
            rel="noopener noreferrer">
            <span className="sidebar-icon">
              <ExternalLink />
            </span>
            <span className="sidebar-item-label">
              학식 KIOSK 앱
            </span>
          </a>
        </li>         
        <li>
        <a
          className="font-weight-normal"
          href={dotNetDownloadLink}
          target="_blank"
          rel="noopener noreferrer">
          <span className="sidebar-icon">
            <ExternalLink />
          </span>
          <span className="sidebar-item-label">
            .NET Framework 4.7.2
          </span>
        </a>
      </li>
    </ul>
  </div>
  <div className="sidebar-menu-box">
    <div className="sidebar-header">
      <span>외부링크</span>
    </div>
    <ul>
      <li>
        <a
          className="font-weight-normal"
          href="http://www.haksik.com"
          target="_blank"
          rel="noopener noreferrer">
          <span className="sidebar-icon">
            <ExternalLink />
          </span>
          <span className="sidebar-item-label">
            학식 홈페이지
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>
</PerfectScrollbar>

: '' }
</>

}
</>

}

    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
