import React from 'react';

import clsx from 'clsx';
import { Nav } from 'reactstrap';
import { connect } from 'react-redux';

import { stringConstantValues } from '../../utils/ConstUtils'

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;
  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent
        })}>
        <div className="app-footer--first">
          <Nav>
          </Nav>
        </div>
        <div className="app-footer--second">
            {stringConstantValues.copyrights} - {stringConstantValues.version}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);
