export const API_GET_DATA         = 'api/GET_DATA';
export const API_GET_DATA_SUCCESS = 'api/GET_DATA_SUCCESS';
export const API_GET_DATA_FAIL    = 'api/GET_DATA_FAIL';
 
export function apiGetData(payload,apicall){
  return {
    type: API_GET_DATA,
    payload,
    apicall
  }
}
 
export function apiGetDataSuccess(data){
  return {
    type: API_GET_DATA_SUCCESS,
    data
  }
}
 
export function apiGetDataFail(error){
  return {
    type: API_GET_DATA_FAIL,
    error
  }
}